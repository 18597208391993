import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";

function Header(props) {
  return (
    <Navbar className="justify-content-start p-2" expand="lg">
      <Navbar.Brand>
        <a href="https://invenxion.com">
          <img src={logo} width="200" height="40" />
        </a>
      </Navbar.Brand>
    </Navbar>
  );
}

export default Header;
