import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Container from "react-bootstrap/Container";

import "./App.css";
import InfoInput from "./components/InfoInput";
import TotalSalaryDetails from "./components/TotalSalaryDetails";
import Header from "./components/Header";

import Notes from "./components/Notes";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  countryList,
  countryWiseTax,
  checkbenefits,
} from "../src/utils/constants/CountryWithCurrency";
import { getKeyByValue, format } from "../src/utils/func/Common";

function App() {
  const [minSalary, setMinSalary] = useState(0);
  const [maxSalary, setMaxSalary] = useState(0);
  const [VPS, setVPS] = useState(false);
  const [healthInsurance, setHealthInsurance] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState("PKR");
  const [selectedCurrency, setSelectedCurrency] = useState("QAR");
  const [invenxionFee, setInvenxionFee] = useState(0);
  const [rates, setRates] = useState([]);
  const [monthlyTax, setMonthlyTax] = useState(0);
  const [minMonthlyTax, setMinMonthlyTax] = useState(0);
  const [maxMonthlyTax, setMaxMonthlyTax] = useState(0);
  const [minInput, setMinInput] = useState(0);
  const [maxInput, setMaxInput] = useState(0);
  useEffect(() => {
    const lastDate = JSON.parse(localStorage.getItem("lastDate"));
    const date = new Date();
    const todaysDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    if (lastDate === todaysDate) {
      const rates = JSON.parse(localStorage.getItem("rates"));
      setRates(rates);
    } else {
      axios
        .get(
          "https://api.apilayer.com/fixer/latest?apikey=ju2TEUJYOGF11enjaNOk6hcCXXJgmQ80"
        )
        .then((response) => {
          const rates = response.data.rates;
          const today = new Date();
          const lastDate =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
          localStorage.setItem("rates", JSON.stringify(rates));
          localStorage.setItem("lastDate", JSON.stringify(lastDate));
          setRates(rates);
        })
        .catch((res) => {
          return res;
        });
    }
  }, []);
  useEffect(() => {}, [healthInsurance, VPS, invenxionFee]);
  useEffect(() => {
    calculateTaxOnInvenxionFee();
    handleMinChange();
    handleMaxChange();
  }, [selectedCurrency, minInput, maxInput]);

  function handleCountryChange(country) {
    const selectedCurrency = getKeyByValue(countryList, country);
    setSelectedCurrency(selectedCurrency);
  }

  function handleMinChange() {
    const amountBeforeTax = format(
      (minInput * rates[selectedCurrency]) / rates[baseCurrency]
    );

    setMinSalary(+amountBeforeTax);
    calculateInvenxionFee();
  }
  function handleMaxChange() {
    const amountBeforeTax = format(
      (maxInput * rates[selectedCurrency]) / rates[baseCurrency]
    );

    setMaxSalary(+amountBeforeTax);
    calculateInvenxionFee();
  }
  function calculateInvenxionFee() {
    if (healthInsurance && VPS) {
      const invenxionFee = format(
        (checkbenefits.withBothBenefits * rates[selectedCurrency]) /
          rates["USD"]
      );
      setInvenxionFee(+invenxionFee);
    } else if (healthInsurance) {
      const invenxionFee = format(
        (checkbenefits.withHealtInsurance * rates[selectedCurrency]) /
          rates["USD"]
      );
      setInvenxionFee(+invenxionFee);
    } else if (VPS) {
      const invenxionFee = format(
        (checkbenefits.WithVPS * rates[selectedCurrency]) / rates["USD"]
      );
      setInvenxionFee(+invenxionFee);
    } else {
      const invenxionFee = format(
        (checkbenefits.basaAmount * rates[selectedCurrency]) / rates["USD"]
      );
      setInvenxionFee(+invenxionFee);
    }

    calculateTaxOnInvenxionFee();
  }
  function calculateTaxOnInvenxionFee() {
    if (selectedCurrency === "USD" || selectedCurrency === "CAD") {
      const extraTaxMin = countryWiseTax[selectedCurrency].min;
      const baseTaxMin = format((extraTaxMin / 100) * invenxionFee);

      const extraTaxMax = countryWiseTax[selectedCurrency].max;
      const baseTaxMax = format((extraTaxMax / 100) * invenxionFee);
      setMonthlyTax(0);
      setMinMonthlyTax(+baseTaxMin);
      setMaxMonthlyTax(+baseTaxMax);
    } else {
      const extraTax = countryWiseTax[selectedCurrency];
      const baseTax = format((extraTax / 100) * invenxionFee);

      setMinMonthlyTax(0);
      setMaxMonthlyTax(0);
      setMonthlyTax(+baseTax);
    }
  }
  function calculateTotalMinSalary() {
    if (healthInsurance && VPS) {
      const addHealthInsurance = format(
        (20000 * rates[selectedCurrency]) / rates[baseCurrency]
      );
      const addVPS = format((10 / 100) * minSalary);
      return +addHealthInsurance + minSalary + +addVPS;
    } else if (healthInsurance) {
      const addHealthInsurance = format(
        (20000 * rates[selectedCurrency]) / rates[baseCurrency]
      );
      return +addHealthInsurance + minSalary;
    } else if (VPS) {
      const addVPS = format((10 / 100) * minSalary);

      return minSalary + +addVPS;
    } else {
      return minSalary;
    }
  }
  function calculateTotalMaxSalary() {
    if (healthInsurance && VPS) {
      const addHealthInsurance = format(
        (20000 * rates[selectedCurrency]) / rates[baseCurrency]
      );
      const addVPS = format((10 / 100) * maxSalary);
      return +addHealthInsurance + maxSalary + +addVPS;
    } else if (healthInsurance) {
      const addHealthInsurance = format(
        (20000 * rates[selectedCurrency]) / rates[baseCurrency]
      );
      return +addHealthInsurance + maxSalary;
    } else if (VPS) {
      const addVPS = format((10 / 100) * maxSalary);

      return maxSalary + +addVPS;
    } else {
      return maxSalary;
    }
  }

  return (
    <div>
      <Header />
      <div className="app ">
        <h1>Enabler</h1>
      </div>

      <Container className="rounded main-container">
        <Container className="pt-5 mt-5 container-all">
          <InfoInput
            onCountryChange={handleCountryChange}
            countries={Object.values(countryList)}
            currency={selectedCurrency}
            onMinChange={(value) => setMinInput(value)}
            onMaxChange={(value) => setMaxInput(value)}
            baseCurrency={baseCurrency}
            onCheckedHealthInsurance={(val) => setHealthInsurance(val)}
            onCheckedVPS={(val) => setVPS(val)}
            healthInsurance={healthInsurance}
            VPS={VPS}
          />
        </Container>
        <Container className="pt-5 container-all">
          <TotalSalaryDetails
            calculateTotalMinSalary={calculateTotalMinSalary()}
            calculateTotalMaxSalary={calculateTotalMaxSalary()}
            invenxionFee={invenxionFee}
            monthlyTax={monthlyTax}
            minMonthlyTax={minMonthlyTax}
            maxMonthlyTax={maxMonthlyTax}
            selectedCurrency={selectedCurrency}
          />
        </Container>
        <Container className="pt-5 pb-5  ">
          <Notes
            className="conversionRate"
            selectedCurrency={selectedCurrency}
            baseCurrency={baseCurrency}
            rates={rates}
          />
        </Container>
      </Container>
    </div>
  );
}

export default App;
