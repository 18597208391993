import PropTypes from "prop-types";
// import "./CountryInput.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
function format(number) {
  return number.toFixed(2);
}

function TotalSalaryDetails(props) {
  return (
    <Row>
      <Col md={6}>
        <div style={{}}>
          <Row className=" justify-content-md-end p-1 ">
            <Col md={5}>Employee Salary Per Month</Col>
            <Col md={5}>
              {props.calculateTotalMinSalary
                ? format(props.calculateTotalMinSalary)
                : 0}{" "}
              -{" "}
              {props.calculateTotalMaxSalary
                ? format(props.calculateTotalMaxSalary)
                : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={5}>Monthly Invenxion Fee</Col>
            <Col md={5}>
              {props.calculateTotalMinSalary ? format(props.invenxionFee) : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={5}>Total Taxes</Col>
            <Col md={5}>
              {props.calculateTotalMinSalary || props.calculateTotalMaxSalary
                ? props.monthlyTax
                  ? format(props.monthlyTax)
                  : format(props.minMonthlyTax) +
                    " - " +
                    format(props.maxMonthlyTax)
                : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={5}>You Pay Per Month</Col>
            <Col md={5}>
              {" "}
              {props.calculateTotalMinSalary
                ? format(
                    props.calculateTotalMinSalary +
                      props.invenxionFee +
                      (props.monthlyTax
                        ? props.monthlyTax
                        : props.minMonthlyTax)
                  )
                : 0}{" "}
              -{" "}
              {props.calculateTotalMaxSalary
                ? format(
                    props.calculateTotalMaxSalary +
                      props.invenxionFee +
                      (props.monthlyTax
                        ? props.monthlyTax
                        : props.maxMonthlyTax)
                  )
                : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={10}>
              <text style={{ fontWeight: "normal" }}>
                * All conversions are in {props.selectedCurrency}
              </text>
            </Col>
          </Row>
        </div>
      </Col>

      <Col md={6}>
        <div style={{}}>
          <Row className="justify-content-md-end p-1">
            <Col md={5}>Employee Salary Per Year</Col>
            <Col md={5}>
              {props.calculateTotalMinSalary
                ? format(props.calculateTotalMinSalary * 12)
                : 0}{" "}
              -{" "}
              {props.calculateTotalMaxSalary
                ? format(props.calculateTotalMaxSalary * 12)
                : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={5}>Yearly Invenxion Fee</Col>
            <Col md={5}>
              {props.calculateTotalMinSalary
                ? format(props.invenxionFee * 12)
                : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={5}>Total Taxes</Col>
            <Col md={5}>
              {props.calculateTotalMinSalary || props.calculateTotalMaxSalary
                ? props.monthlyTax
                  ? format(props.monthlyTax * 12)
                  : format(props.minMonthlyTax * 12) +
                    " - " +
                    format(props.maxMonthlyTax * 12)
                : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={5}>You Pay Per Year</Col>
            <Col md={5}>
              {" "}
              {props.calculateTotalMinSalary
                ? format(
                    (props.calculateTotalMinSalary +
                      props.invenxionFee +
                      (props.monthlyTax
                        ? props.monthlyTax
                        : props.minMonthlyTax)) *
                      12
                  )
                : 0}{" "}
              -{" "}
              {props.calculateTotalMaxSalary
                ? format(
                    (props.calculateTotalMaxSalary +
                      (props.invenxionFee +
                        (props.monthlyTax
                          ? props.monthlyTax
                          : props.maxMonthlyTax))) *
                      12
                  )
                : 0}
            </Col>
          </Row>
          <Row className="justify-content-md-end p-1">
            <Col md={10}>
              <text style={{ fontWeight: "normal" }}>
                * All conversions are in {props.selectedCurrency}
              </text>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default TotalSalaryDetails;
