import PropTypes from "prop-types";
// import "./CountryInput.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ListGroup from "react-bootstrap/ListGroup";

function format(number) {
  return number.toFixed(2);
}
function Notes(props) {
  return (
    <Row>
      <Col md={12}>
        <Row className="justify-content-md-center  ">
          <ListGroup numbered>
            <ListGroup.Item className="border-0  bg-transparent text-white">
              Invenxion fee is fixed at 150 USD for each salary processed.
            </ListGroup.Item>
            <ListGroup.Item className="border-0  bg-transparent text-white">
              Calculations are based on todays forex rates and are subject to
              change.
            </ListGroup.Item>

            <ListGroup.Item className="border-0  bg-transparent text-white">
              Employees get all benefits enabled via enabler directly, health
              insurance is billed annually.
            </ListGroup.Item>
          </ListGroup>
        </Row>
      </Col>
      {/* <Row>
        <Col md={12}>
          <ListGroup numbered>
            <ListGroup.Item className="border-0  bg-transparent text-white">
              Invenxion fee is fixed at 150 USD for each salary processed.
            </ListGroup.Item>
            <ListGroup.Item className="border-0  bg-transparent text-white">
              Calculations are based on todays forex rates and are subject to
              change.
            </ListGroup.Item>
            <ListGroup.Item className="border-0  bg-transparent text-white">
              Morbi leasdo risus
            </ListGroup.Item>
            <ListGroup.Item className="border-0  bg-transparent text-white">
              Employees get all benefits enabled via enabler directly, health
              insurance is billed annually.
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row> */}
    </Row>
    // <div>
    //   Today's Conversion Rate
    //   <div>
    // {props.selectedCurrency} - {props.baseCurrency} : 1 -
    // {format(
    //   (1 * props.rates[props.baseCurrency]) /
    //     props.rates[props.selectedCurrency]
    // )}
    //   </div>
    //   <div>{props.selectedCurrency} - USD:</div> 1 -
    //   {format((1 * props.rates[props.selectedCurrency]) / props.rates["USD"])}
    // </div>
  );
}

export default Notes;
