import PropTypes from "prop-types";
import "../App.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
function InfoInput(props) {
  return (
    <Row className="justify-content-md-center">
      <Col md={7}>
        <div style={{}}>
          <Row className="justify-content-md-center p-2">
            <Col md={4}>Select Country</Col>
            <Col md={4}>
              {/* <div style={{ background: "red" }}> */}
              <Form.Select
                size="sm"
                value={props.country}
                onChange={(ev) => props.onCountryChange(ev.target.value)}
              >
                {props.countries.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              {/* </div> */}
            </Col>
          </Row>
          <Row className="justify-content-md-center p-2">
            <Col md={4}>
              <div style={{}}>Min Salary Range</div>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={10}>
                  <Form.Control
                    min={0}
                    maxLength={99999999}
                    step={10000}
                    size="sm"
                    type="number"
                    onChange={(e) => {
                      props.onMinChange(
                        (e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 8))
                      );
                    }}
                  />
                </Col>
                <Col md={2}>{props.baseCurrency}</Col>
              </Row>
              {/* <div style={{flexDirection:'row'}}> */}

              {/* </div> */}
            </Col>
          </Row>
          <Row className="justify-content-md-center p-2">
            <Col md={4}>
              <div style={{}}>Max Salary Range</div>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={10}>
                  <Form.Control
                    min={0}
                    max={99999999}
                    step={10000}
                    size="sm"
                    type="number"
                    onChange={(e) => {
                      props.onMaxChange(
                        (e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 8))
                      );
                    }}
                  />
                </Col>
                <Col md={2}>{props.baseCurrency}</Col>
              </Row>
              {/* <div style={{flexDirection:'row'}}> */}

              {/* </div> */}
            </Col>
            {/* <Col md={4}>
              <div style={{}}>
                <Form.Control
                  size="sm"
                  type="number"
                  onChange={(e) => {
                    props.onMaxChange(e.target.value);
                  }}
                />
              </div>
            </Col> */}
          </Row>
          <Row className="justify-content-md-center p-2">
            <Col md={4}>
              <div style={{}}>Benefits</div>
            </Col>
            <Col md={4}>
              <Form.Check
                type="checkbox"
                label="Health Insurance"
                defaultChecked={props.healthInsurance}
                onChange={() => {
                  props.onCheckedHealthInsurance(!props.healthInsurance);
                }}
              />
              <Form.Check
                type="checkbox"
                label="Voluntary Pension Scheme"
                defaultChecked={props.VPS}
                onChange={() => {
                  props.onCheckedVPS(!props.VPS);
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default InfoInput;
